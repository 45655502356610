@import url('https://fonts.googleapis.com/css2?family=Lora:wght@600&family=Merriweather:wght@300&display=swap');

body {
	color: #333;
	margin: 0;
	padding: 0;
	font-size: 20px;
	line-height: 1.8;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	@media (max-width: 800px) {
		font-size: 16px;
	}
}

* {
	box-sizing: border-box;
}
// Global Typography

h1,
h2,
h3 {
	position: relative;
	padding-bottom: 1rem;
	margin-bottom: 1rem;
	line-height: 1.2;
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		width: 100px;
		height: 1px;
		background: #000;
		left: 0;
	}
}

h1 {
	font-size: 50px;
	@media (max-width: 800px) {
		font-size: 30px;
	}
}

.btn {
	cursor: pointer;
	display: inline-block;
	margin-top: 1rem;
	color: #000;
	text-decoration: none;
	padding-bottom: 0.5rem;
	border: 0px;
	border-bottom: 1px solid #000;
	appearance: none;
	background: none;
	font-size: 20px;
	margin-top: 1.5rem;
	@media (max-width: 800px) {
		font-size: 16px;
	}
	&:hover {
		font-weight: bold;
	}
}

// Global Layout

.container {
	box-sizing: border-box;
	min-height: 100vh;
	width: 100%;
	position: static;
	display: flex;
	align-items: center;
	justify-content: center;
	.inner {
		max-width: 800px;
	}
	@media (max-width: 800px) {
		padding: 2rem;
		padding-top: 70px;
		padding-bottom: 70px;
	}
}

#Loader {
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

#Layout {
	#Header {
		position: fixed;
		height: 70px;
		z-index: 99;
		top: 0;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1rem;
		.logo {
			height: 40px;
			width: auto;
		}
		.menuButton {
			height: 30px;
			width: 30px;
			cursor: pointer;
			transition: all 0.2s ease;
			&:hover {
				transition: all 0.2s ease;
				transform: scale(0.8);
			}
		}
	}
	.menu {
		position: fixed;
		background: #000;
		height: 100%;
		width: 400px;
		max-width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 99;
		right: 0;
		.close {
			position: absolute;
			top: 2rem;
			right: 2rem;
			height: 25px;
			width: 25px;
			cursor: pointer;
			transition: all 0.2s ease;
			&:hover {
				transition: all 0.2s ease;
				transform: scale(0.8);
			}
		}
		ul {
			margin: 0;
			padding: 0;
			li {
				margin-bottom: 0.5rem;
				list-style: none;
				a {
					color: #fff;
					text-decoration: none;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}

#Intro,
#Outro {
	padding: 2rem;
	img {
		width: 250px;
		height: auto;
		margin-bottom: 1.5rem;
	}
	.inner {
		text-align: center;
		h1 {
			&:after {
				transform: translateX(-50%);
				left: 50%;
			}
		}
		.text {
			margin: 2rem auto;
		}
	}
}

#QuestionView {
	.inner {
		max-width: 600px;
	}
	.error {
		position: static;
		color: darkred;
	}
	.answers {
		margin: 2rem 0;
		display: grid;
		grid-gap: 1.5rem;
		grid-template-columns: 1fr 1fr;

		.answer {
			cursor: pointer;
			line-height: 1.4;
			label {
				cursor: pointer;
				margin-left: 40px;
				display: block;
			}
			&:hover label {
				font-weight: bold;
				transition: all 0.2s ease;
			}
			.radio {
				cursor: pointer;
				float: left;
				appearance: none;
				height: 20px;
				width: 20px;
				border: 1px solid #333;
				border-radius: 50%;
				transform: translateY(3px);
				position: relative;
				&:after {
					content: '';
					transform: scale(0);
					height: 20px;
					width: 20px;
					left: 2px;
					top: -2px;
					position: absolute;
					background-size: contain;
					background-repeat: no-repeat;

					transition: all 0.2s ease;
					background-image: url(../images/check.svg);
				}
				&.checked:after {
					transition: all 0.2s ease;
					transform: scale(1);
				}
			}
		}
		@media (max-width: 800px) {
			display: block;
			.answer {
				margin-bottom: 1rem;
				.radio {
					height: 16px;
					width: 16px;
					&:after {
						height: 16px;
						width: 16px;
						top: -2px;
						left: 2px;
					}
				}
			}
		}
	}
}

#SolutionView {
	align-items: stretch;
	.image {
		width: 50%;
		background: grey;
		min-height: 100%;
		background-size: cover;
		background-position: center;
	}
	.content {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50%;
		padding: 50px;
		box-sizing: border-box;
	}
	@media (max-width: 800px) {
		display: block;
		.image {
			width: 100%;
			height: 300px;
		}
		.content {
			margin-top: 1.5rem;
			width: 100%;
			padding: 0;
		}
	}
}
